import {heightControl, contractNameDecomposer, displayCommodity} from '@/js/main';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import $ from "jquery";
import moment from "moment";
// import * as fb from '@/firebase';
const axios = require('axios');

const cot = {
    mounted() {
        // console.log("mixins/cot.js mounted() starting. this.namespace=", this.namespace);
    },
    computed: {
        addCOTPanel() {
            return this.$store.state[this.namespace].addCOTPanel;
        }
    },
    watch: {
        addCOTPanel(addCOTPanel){
            console.log("watch: addCOTPanel=", addCOTPanel);
            addCOTPanel ? this.addCotPanel() : this.removeCotPanel();
        }
    },
    methods: {
        removeCotPanel() {
            // console.log("removeCotPanel() starting.");
            let chart = this.$options.chart;

            if(typeof chart.series !== 'undefined'){
            chart.series.removeIndex(chart.series.indexOf(chart.map.getKey("commercial-series"))).dispose();
            chart.series.removeIndex(chart.series.indexOf(chart.map.getKey("nonCommercial-series"))).dispose();
            chart.series.removeIndex(chart.series.indexOf(chart.map.getKey("smallTrader-series"))).dispose();

            chart.yAxes.removeIndex(chart.yAxes.indexOf(chart.map.getKey("cot-axis"))).dispose();
            heightControl("removing", chart);
            }
        },
        addCotPanel() {
           // console.log("addCotPanel() starting.");
           
            let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
           
            if(!this.aligned){
            let chart = this.$options.chart;

            let commercialSeries = chart.map.getKey("commercial");
            // console.log("commercialSeries=", commercialSeries);
            let formatString = "#,###.";

            let alreadyPresent = typeof commercialSeries !== 'undefined';
           // console.log("alreadyPresent=", alreadyPresent);

            if (!alreadyPresent) {
                this.$store.commit('resetNumberOfAjaxCalls');
                this.$store.commit('incrementNumberOfAjaxCalls');

                let commodity = contractNameDecomposer(generalForm.sampleContract[0]).commoditySymbol;
                // let storage = fb.storage;
                let url = "https://storage.googleapis.com/authentication-f0d7e.appspot.com/cot/" + commodity + ".txt";

                axios.get(url)
                        .then(apiResponse => {
                            this.$store.commit('decrementNumberOfAjaxCalls');
                           // console.log("apiResponse=", apiResponse);
                            let cotData = apiResponse.data;
                            console.log("cotData=", cotData);
                          //  let lastCotDate = cotData[cotData.length - 1].date;
                           // console.log("lastCotDate=", lastCotDate);

                            $("#no_cot_message").remove();
                            // let start = generalForm.chartParameters.start;
                            // console.log("start=", start);

                          //  console.log("chart=", chart);
                          //  console.log("this.program=", this.program);
                            let mainData = chart.map.getKey("series-0").data;
                            let dateDataArray = chart.series.values.filter(x => x.id.startsWith("series-")).map(x => ({id: x.id, data: x.data}));
                            console.log("dateDataArray=", dateDataArray);
                           // console.log("mainData=", mainData);
                          //  console.log("mainData=", JSON.parse(JSON.stringify(mainData)));

                            let firstMainDataDateArray = dateDataArray.map(series => {
                               // console.log("series=", series);
                                return series.data[0].date;
                            }).sort((a, b) => moment(a.date).diff(moment(b.date)));
                            console.log("firstMainDataDateArray=", firstMainDataDateArray);

                            let firstMainDataDate = this.program === "BasicCharts" ? mainData[0].date :
                                                                                     firstMainDataDateArray[firstMainDataDateArray.length-1];
                            console.log("firstMainDataDate=", moment(firstMainDataDate).format("YYYYMMDD"));

                          /*  let previousCotData = cotData.filter(x => moment(x.date, "YYYYMMDD").isBefore(moment(firstMainDataDate, "YYYYMMDD")));
                           // console.log("previousCotData=", previousCotData);
                            let previousBar = previousCotData[previousCotData.length - 1];
                            previousBar.commercial = previousBar.com;
                            previousBar.nonCommercial = previousBar.noncom;
                            previousBar.smallTrader = previousBar.small;*/

                          /*  delete previousBar.com;
                            delete previousBar.noncom;
                            delete previousBar.small;*/
                            // console.log("previousBar=", previousBar);

                            let currentCotData = cotData.filter(x => !moment(x.date, "YYYYMMDD").isBefore(moment(firstMainDataDate, "YYYYMMDD")));
                            console.log("currentCotData=", currentCotData);

                            currentCotData.forEach(function (cotBar) {
                               // console.log("cotBar=", JSON.parse(JSON.stringify(cotBar)));
                               // console.log("cotBar.date=",cotBar.date);

                               let cotBarDate = moment(cotBar.date, "YYYYMMDD").format("YYYY-MM-DD");
                                cotBar.date = cotBarDate;
                            });
                           // console.log("mainData=", JSON.parse(JSON.stringify(mainData)));

                            let sortedData = currentCotData;  //mainData.slice().sort((a, b) => moment(a.date).diff(moment(b.date)));
                            console.log("sortedData=", JSON.parse(JSON.stringify(sortedData)));

                            let axisPercentage = this.program === "LongTermCharts" ? 50 : heightControl("adding", chart);

                           // console.log("chart.yAxes id's=", chart.yAxes.values.map(x => x.id));
                            if(chart.yAxes.values.map(x => x.id).includes("cot-axis")){
                                this.removeCotPanel();
                            }
                            let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                            valueAxis.id = "cot-axis";
                            valueAxis.tooltip.disabled = true;
                            valueAxis.height = am4core.percent(axisPercentage);
                            valueAxis.zIndex = 3;
// this makes gap between panels
                            valueAxis.marginTop = 16;
                            valueAxis.renderer.baseGrid.disabled = true;
                            valueAxis.renderer.inside = true;
                            valueAxis.renderer.labels.template.verticalCenter = "bottom";
                            valueAxis.renderer.labels.template.padding(2, 2, 2, 2);
//valueAxis.renderer.maxLabelPosition = 0.95;
                            valueAxis.renderer.fontSize = "0.8em";
                            valueAxis.renderer.gridContainer.background.fillOpacity = 0.05;
                            valueAxis.title.text = "COT of " + displayCommodity(commodity, this.$store.state.user.symbols);
                            valueAxis.title.fontWeight = "bold";
                            valueAxis.zoomable = false;

                            let commercialSeries = chart.series.push(new am4charts.StepLineSeries());
                            commercialSeries.id = "commercial-series";
                            commercialSeries.data = sortedData;
                            commercialSeries.dataFields.valueY = "com";
                            commercialSeries.dataFields.dateX = "date";
                            commercialSeries.yAxis = valueAxis;
                            commercialSeries.name = "commercialSeries";
                            commercialSeries.strokeWidth = 1;
                            commercialSeries.stroke = "red";
                            commercialSeries.fillOpacity = 0.0;
                            commercialSeries.stacked = false;
                            commercialSeries.legendSettings.labelText = "[{color}]com: {valueY}";
                            commercialSeries.hiddenInLegend = true;
                            commercialSeries.tooltipText = "commercial: {valueY.value.formatNumber('" + formatString + "')}";
                            commercialSeries.fill = "red";
                            commercialSeries.showOnInit = false;

                            let nonCommercialSeries = chart.series.push(new am4charts.StepLineSeries());
                            nonCommercialSeries.id = "nonCommercial-series";
                            nonCommercialSeries.data = sortedData;
                            nonCommercialSeries.dataFields.valueY = "noncom";
                            nonCommercialSeries.dataFields.dateX = "date";
                            nonCommercialSeries.yAxis = valueAxis;
                            nonCommercialSeries.name = "nonCommercialSeries";
                            nonCommercialSeries.strokeWidth = 1;
                            nonCommercialSeries.stroke = "blue";
                            nonCommercialSeries.fillOpacity = 0.0;
                            nonCommercialSeries.stacked = false;
                            nonCommercialSeries.legendSettings.labelText = "[{color}]non-com: {valueY}";
                            nonCommercialSeries.hiddenInLegend = true;
                            nonCommercialSeries.tooltipText = "non-commercial: {valueY.value.formatNumber('" + formatString + "')}";
                            nonCommercialSeries.fill = "blue";
                            nonCommercialSeries.showOnInit = false;

                            let smallTraderSeries = chart.series.push(new am4charts.StepLineSeries());
                            smallTraderSeries.id = "smallTrader-series";
                            smallTraderSeries.data = sortedData;
                            smallTraderSeries.dataFields.valueY = "small";
                            smallTraderSeries.dataFields.dateX = "date";
                            smallTraderSeries.yAxis = valueAxis;
                            smallTraderSeries.name = "smallTraderSeries";
                            smallTraderSeries.strokeWidth = 1;
                            smallTraderSeries.stroke = "black";
                            smallTraderSeries.stacked = false;
                            smallTraderSeries.tooltipText = "small trader: {valueY.value.formatNumber('" + formatString + "')}";
                            smallTraderSeries.fill = "black";
                            smallTraderSeries.hiddenInLegend = true;
                            smallTraderSeries.legendSettings.labelText = "[{color}]sm trader: {valueY}";
                            smallTraderSeries.showOnInit = false;

                            console.log("cot loaded.");
                          /*  valueAxis.events.once("startendchanged", () => {
                                //chart.zoomAxes(chart.yAxes, {start: 0, end: 1});
                                // console.log("valueAxis._minReal=", valueAxis._minReal);
                                //  console.log("valueAxis._maxReal=", valueAxis._maxReal);

                                valueAxis.zoomToValues(valueAxis._minReal, valueAxis._maxReal);
                                this.$store.commit('decrementNumberOfAjaxCalls');

                                // let dateAxis = chart.map.getKey("date-axis");
                                // dateAxis.zoomToDates(0,1);

                            }); */
                        })
                        .catch((err) => {
                            this.$store.commit('decrementNumberOfAjaxCalls');
                            console.log("err=", err);
                            $("#" + generalForm.moduleName + "-chartDiv").before("<span id='no_cot_message' style='font-size: 12px; color: red; text-align: left; padding: 2px'>There is no commitment of traders data for this commodity.</span>");
                        });
                }
            }
        },
        setCotTooltips() {
            console.log("setCotTooltips() starting.");
            let chart = this.$options.chart;
            let formatString = "#,###.";

            let commercialSeries = chart.map.getKey("commercial-series");
            let nonCommercialSeries = chart.map.getKey("nonCommercial-series");
            let smallTraderSeries = chart.map.getKey("smallTrader-series");
            // console.log("rsiSeries=", series);
            if (typeof commercialSeries !== 'undefined') {
                commercialSeries.tooltipText = this.balloons ? "commercial: {valueY.value.formatNumber('" + formatString + "')}" : "";
                nonCommercialSeries.tooltipText = this.balloons ? "non-commercial: {valueY.value.formatNumber('" + formatString + "')}" : "";
                smallTraderSeries.tooltipText = this.balloons ? "small trader: {valueY.value.formatNumber('" + formatString + "')}" : "";
            }
        }
    }
};

export default cot;