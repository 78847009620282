import * as am5 from "@amcharts/amcharts5";
import moment from "moment";

const seasonals = {
    openDataItem: {}, // <-- non-reactive property
    closeDataItem: {}, // <-- non-reactive property
    tradingPeriodRangeDataItem: "", // <-- non-reactive property

    mounted() {
        // console.log("mixins/tradingPeriod.js mounted() starting. this.namespace=", this.namespace);
    },
    computed: {
        buySell() {
            return this.generalForm.buySell;
        },
        showTradingPeriod() {
            return this.generalForm.showTradingPeriod;
        },            
        open() {
            return this.generalForm.open;
        },
        close() {
            return this.generalForm.close;
        },
    },
    watch: {
        showTradingPeriod: function (showTradingPeriod) {
            console.log("watch showTradingPeriod = ", showTradingPeriod);
            showTradingPeriod ? this.addTradingPeriod() : this.removeTradingPeriod();
        },
        buySell() {
            console.log("watching buySell");
            this.addTradingPeriod();
        },
        open: function (newOpen, oldOpen) {
            console.log("watch: newOpen=", newOpen, " oldOpen=", oldOpen);
            // console.log("this.$options.root=", this.$options.root);
            if (Object.keys(this.$options.root).length > 0) {
                this.addTradingPeriod();
            }
        },
        close: function (newClose, oldClose) {
            console.log("watch: newClose=", newClose, " oldClose=", oldClose);
            if (Object.keys(this.$options.root).length > 0) {
                this.addTradingPeriod();
            }
        }
    },
    methods: {
        removeTradingPeriod() {
            console.log("removeTradingPeriod() starting.");
            let dateAxis = am5.registry.entitiesById["dateAxis-" + this.namespace];
            dateAxis.axisRanges.removeValue(this.$options.openDataItem);
            dateAxis.axisRanges.removeValue(this.$options.closeDataItem);
            dateAxis.axisRanges.removeValue(this.$options.tradingPeriodRangeDataItem);

        },
        addTradingPeriod() {
            console.log("addTradingPeriod() starting.");
            this.removeTradingPeriod();

            if(this.showTradingPeriod){
            let generalForm = JSON.parse(JSON.stringify(this.generalForm));
            let dateAxis = am5.registry.entitiesById["dateAxis-" + this.namespace];

            let open = moment(generalForm.open).add(12, 'hours');
            let close = moment(generalForm.close).add(12, 'hours');

             console.log("open=", open);
                
            if (generalForm.open !== "") {
                this.$options.openDataItem = dateAxis.makeDataItem({ value: open.valueOf() });
                dateAxis.createAxisRange(this.$options.openDataItem);

                this.$options.openDataItem.get("grid").setAll({
                    strokeOpacity: 1,
                    stroke: this.buySell === "buy" ? "green" : "red",
                    strokeWidth: 2
                });

                this.$options.openDataItem.get("label").setAll({
                    inside: true,
                    text: open.format("MMM D, YYYY"),
                    fontSize: 11,
                    fontWeight: "400",
                    rotation: 90,
                    centerX: am5.p100,
                    centerY: am5.p100,
                    location: 0,
                    paddingBottom: -15,
                    paddingRight: 15,
                    fill: this.buySell === "buy" ? "green" : "red"
                });
            }

            if (generalForm.close !== "") {
                this.$options.closeDataItem = dateAxis.makeDataItem({ value: close.valueOf() });
                dateAxis.createAxisRange(this.$options.closeDataItem);

                this.$options.closeDataItem.get("grid").setAll({
                    strokeOpacity: 1,
                    stroke: this.buySell !== "buy" ? "green" : "red",
                    strokeWidth: 2
                });

                this.$options.closeDataItem.get("label").setAll({
                    inside: true,
                    affectsMinMax: true,
                    text: close.format("MMM D, YYYY"),
                    fontSize: 11,
                    fontWeight: "400",
                    rotation: 90,
                    centerX: am5.p100,
                    centerY: am5.p100,
                    location: 0,
                    paddingBottom: -15,
                    paddingRight: 15,
                    fill: this.buySell === "buy" ? "red" : "green"
                });
            }

            if (generalForm.open !== "" && generalForm.close !== "") {

                this.$options.tradingPeriodRangeDataItem = dateAxis.makeDataItem({
                    value: open.valueOf(), endValue: close.valueOf(),
                    affectsMinMax: true
                });

                dateAxis.createAxisRange(this.$options.tradingPeriodRangeDataItem);
                this.$options.tradingPeriodRangeDataItem.get("axisFill").setAll({ fill: "gold", opacity: 0.4, visible: true });
                this.$options.tradingPeriodRangeDataItem.get("grid").set("forceHidden", true);

                console.log("dateAxis.axisRanges=", dateAxis.axisRanges);
            }
        }
        },
    }
};

export default seasonals;