import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5stock from "@amcharts/amcharts5/stock";
// import moment from "moment";
import $ from "jquery";
import _ from "lodash";
// import { bus } from "@/main";

const seasonals = {
    seasonalDataArray: [], // <-- non-reactive property
    mounted() {
        // console.log("mixins/seasonals.js mounted() starting. this.namespace=", this.namespace);
    },
    computed: {
        availableSeasonals() {
            return this.$store.getters[this.namespace + "/availableSeasonals"];
        },
        seasonalTypes: {
            get() {
                return this.generalForm.seasonalTypes;
            },
            set(seasonalTypes) {
                this.$store.commit(this.namespace + "/setSeasonalTypes", seasonalTypes);
            }
        },
    },
    watch: {
        availableSeasonals: function (newAvailableSeasonals, oldAvailableSeasonals) {
           // console.log("watch newAvailableSeasonals=", newAvailableSeasonals.slice(0), " oldAvailableSeasonals=", oldAvailableSeasonals.slice(0), " namespace=", this.namespace);
            let seasonalsToRemove = oldAvailableSeasonals.filter((x) => !this.availableSeasonals.includes(x));
            // console.log("seasonalsToRemove =", seasonalsToRemove);

            if (seasonalsToRemove.length > 0) {
                this.removeSeasonals(seasonalsToRemove);
            }

            if (newAvailableSeasonals.length > oldAvailableSeasonals.length) {
                let seasonalToAdd = newAvailableSeasonals.find(x => !oldAvailableSeasonals.includes(x));
                console.log("seasonalToAdd=", seasonalToAdd);
                let seasonalColorOptions = this.$store.getters[this.namespace + "/seasonalOptions"];
                console.log("seasonalColorOptions=", seasonalColorOptions);
                // this.callGetSeasonalData();
                this.getSeasonalData().then(seasonalDataArray => {
                    console.log("seasonalDataArray=", JSON.parse(JSON.stringify(seasonalDataArray)));
                    this.handleSeasonalData(seasonalDataArray);
                });
            }

            if (newAvailableSeasonals.length < oldAvailableSeasonals.length) {
                //  oldSeasonals = this.seasonals.slice(0);
            }
        },
        seasonalTypes(newSeasonalTypes, oldSeasonalTypes) {
            // console.log("watching newSeasonalTypes=", newSeasonalTypes, " oldSeasonalTypes=", oldSeasonalTypes);
            if (JSON.stringify(newSeasonalTypes) !== JSON.stringify(oldSeasonalTypes)) {
                this.getSeasonalData().then(seasonalDataArray => {
                    console.log("seasonalDataArray=", JSON.parse(JSON.stringify(seasonalDataArray)));
                    this.handleSeasonalData(seasonalDataArray);
                });
            }
        },
    },
    methods: {
        handleSeasonalData(seasonalDataArray) {
          //  console.log("handleSeasonalData() starting.");
            return new Promise((resolve) => {
                
                let fields = this.$store.getters[this.namespace + "/availableSeasonals"].map(x => x.toString());
           // console.log("fields=", fields);

           // console.log("am5.registry.entitiesById=", am5.registry.entitiesById);
           // console.log("am5.registry.entitiesById=", {...am5.registry.entitiesById});
           // console.log("this.$options.root=", this.$options.root);
            let seasonalDataProcessor = am5.DataProcessor.new(this.$options.root, {
                dateFields: ["date"],
                dateFormat: "yyyyMMdd",
                numericFields: fields
                        // numericFields: ["open", "high", "low", "close", "openInterest", "volume"]
            });
            seasonalDataProcessor.processMany(seasonalDataArray);
           // console.log("seasonalDataArray=", JSON.parse(JSON.stringify(seasonalDataArray)));
            this.$options.seasonalDataArray = seasonalDataArray;

          //  console.log("this.seasonalTypes=", this.seasonalTypes);
            //  console.log("this.program=", this.program);
            if (this.seasonalTypes.includes("unadjusted")) {
                this.addSeasonals().then(/*addSeasonalsResult*/ () => {
                  //  console.log("addSeasonalsResult=", addSeasonalsResult);
                    resolve("seasonals ready.");
                });
            } else {
                this.removeSeasonals(this.generalForm.seasonals);
                resolve("seasonals ready.");

            }
            if (this.generalForm.seasonalTypes.includes("adjusted")) {
                this.addAdjustedSeasonals().then(/*addAdjustedSeasonalsResult*/ () => {
                   // console.log("addAdjustedSeasonalsResult=", addAdjustedSeasonalsResult);
                    resolve("adjusted seasonals ready.");
                });
            } else {
                this.removeAdjustedSeasonals(this.generalForm.seasonals);
                resolve("adjusted seasonals ready.");
            }
        });
    },
        callGetSeasonalData: _.debounce(function () {
            console.log("callGetSeasonalData() starting.");
            this.getSeasonalData();
        }, 100),
        async getSeasonalData() {
            console.log("getSeasonalData() starting.");
            let that = this;
            let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));

            return new Promise((resolve) => {
                if (generalForm.seasonals.length > 0 && generalForm.instrument === "future") {
                    that.$store.commit('incrementNumberOfAjaxCalls');
                    delete generalForm.browserSideOnly;
                    delete generalForm.playback;
                    delete generalForm.search;
                    delete generalForm.indicators;
                    let json = JSON.stringify(generalForm, null, 2);

                    $.ajax({
                        url: that.$store.state.siteData.baseUrl + that.$store.state.siteData.contextName + "/StandardSeasonalsServlet/",
                        type: "POST",
                        data: {"requestJson": json},
                        success: function (data) {
                            that.$store.commit('decrementNumberOfAjaxCalls');
                            // console.log("data=", data);
                            let parsedData = JSON.parse(data);
                           // console.log("seasonal parsedData=", parsedData);
                            //  console.log("that.seasonalDataArray=", JSON.parse(JSON.stringify(that.seasonalDataArray)));

                            if (typeof parsedData.dataset !== 'undefined') {
                                // let returnedSeasonals = parsedData.dataset.map(x => parseInt(x.split('-')[0]));
                                // console.log("returnedSeasonals=", returnedSeasonals);

                                let dateArray = Object.keys(parsedData.values)
                                        .map(date => ({date: date, ...parsedData.values[date]}));
                                // console.log("dateArray=", [...dateArray]);

                                // let concatenatedSeries = [...that.seasonalDataArray, ...additionalSeasonalDataArray];
                                //console.log("concatenatedSeries=", concatenatedSeries);
                                let uniqueDates = [...new Set(dateArray.map(x => x.date))].sort();
                                // console.log("uniqueDates=", uniqueDates);

                                let seasonalDataArray = uniqueDates.map(date => (
                                            dateArray.filter(x => x.date == date)
                                            .reduce((combinedBar, bar) => ({...combinedBar, ...bar}), {})
                                            ));

                                let isDateATimestamp = seasonalDataArray[0].date.toString().length === 8 ? false : true;

                                if (!isDateATimestamp) {
                                    seasonalDataArray.forEach(bar => {
                                        bar.date = bar.date.toString();
                                        bar.readableDate = bar.date;
                                    });
                                  //  console.log("isDateATimestamp=", isDateATimestamp);

                                    resolve(seasonalDataArray);

                                    /*   */
                                }
                                // console.log("seasonalDataArray=", JSON.parse(JSON.stringify(seasonalDataArray)));
                                //  that.$options.seasonalDataArray = seasonalDataArray;

                                /*    */

                            } else {
                                resolve([]);
                               // bus.$emit("setChartHeight");
                            }

                            if (typeof that.seasonalDataArray !== 'undefined') {
                               // console.log("that.seasonalDataArray=", JSON.parse(JSON.stringify(that.seasonalDataArray)));
                            }
                        },
                        fail: function (data) {
                            console.log("ajax call failed.");
                            that.$store.commit('decrementNumberOfAjaxCalls');
                            $("#progress").hide();
                            $("#button").show();
                            console.log(data);
                        }
                        // });
                    });
                } else {
                   // bus.$emit("setChartHeight");
                    resolve([]);
                }
            });
        },
        addSeasonals() {
            console.log("addSeasonals() starting.");
            let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
            return new Promise((resolve) => {

            let that = this;
            generalForm.seasonals.forEach(years => {
                let indicator = am5.registry.entitiesById[years + "-y_seasonalIndicator-" + this.namespace];

                // console.log("indicator=", indicator);
                if (typeof indicator !== 'undefined' && typeof indicator.show !== "undefined") {
                    // console.log("indicator._settings=", indicator._settings);
                    // console.log('typeof indicator.show === "undefined"=', typeof indicator.show === "undefined");
                    try {
                        indicator.show();
                    } catch (err) {
                        console.log("err=", err);
                        that.addSeasonalIndicator(years).then(seasonalResult => {
                            console.log("seasonalResult=", seasonalResult);
                            resolve("seasonal added.");
                        });
                    }
                } else {
                    that.addSeasonalIndicator(years).then(seasonalResult => {
                        console.log("seasonalResult=", seasonalResult);
                        resolve("seasonal added.");
                    });
                }
            });
        });
    },
        removeSeasonals(seasonalsToRemove) {
            // console.log("removeSeasonals() starting. seasonalsToRemove=", seasonalsToRemove);
            // console.trace();
            seasonalsToRemove.forEach(years => {
                this.removeSeasonalIndicator(years);
            });
        },
        removeSeasonalIndicator(years) {
           // console.log("removeSeasonalIndicator() starting. years=", years);
            // console.trace();
            let indicator = am5.registry.entitiesById[years + "-y_seasonalIndicator-" + this.namespace];
            // console.log("indicator=", indicator);
            // console.log("am5.registry.entitiesById=", am5.registry.entitiesById);
            // console.log("am5.registry.entitiesById=", { ...am5.registry.entitiesById });
            if (typeof indicator !== 'undefined') {
                //indicator.hide();
                indicator.dispose();
            }
            this.removeAdjustedSeasonalIndicator(years);

           // bus.$emit("setChartHeight");
        },
        addSeasonalIndicator(years) {
            console.log("addSeasonalIndicator() starting. years=", years + " this.namespace=", this.namespace);
            let that = this;
            return new Promise((resolve) => {

            let seasonalColorOptions = that.$store.getters[that.namespace + "/seasonalOptions"];
            // console.log("seasonalColorOptions=", seasonalColorOptions);

            let colorOption = seasonalColorOptions.find(x => x.value == years);
            // console.log("colorOption=", colorOption);

            if (typeof colorOption !== 'undefined') {
                let color = colorOption.color;

                let sbSeries = am5.registry.entitiesById["sbSeries-" + this.namespace];

                // Create a custom indicator
                // -------------------------------------------------------------------------------

                // Define indicator class
                class SeasonalIndicator extends am5stock.Indicator {
                    className = "SeasonalIndicator"; // may be causing a problem.

                    _afterNew() {

                        // Set default indicator name
                        this._setDefault("name", years + "y-seasonal");
                        // this._setDefault("margin", 100);
                        // this._setDefault("seriesColor", am5.color(0x045153));
                        //  this._setDefault("seriesStyle", "Solid");
                        //  this._setDefault("showFill", false);

                        // Setting up indicator elements
                        let stockSeries = this.get("stockSeries");
                        let scrollbar = stockSeries.chart.get('scrollbarX');

                        let chart = stockSeries.chart;

                        let sbChart;
                        if (typeof scrollbar !== 'undefined') {
                            sbChart = scrollbar.chart;
                        }

                        if (chart) {
                            // console.log("years=", years);

                            let series = chart.series.push(am5xy.LineSeries.new(that.$options.root, {
                                valueXField: "date",
                                valueYField: years,
                                groupDataDisabled: false,
                                calculateAggregates: true,
                                xAxis: stockSeries.get("xAxis"),
                                yAxis: stockSeries.get("yAxis"),
                                themeTags: ["indicator"],
                                name: years + "-y seasonal",
                                legendLabelText: "{name}",
                                legendValueText: years + "-y seasonal: [bold]{valueY}[/]",
                                legendRangeValueText: "",
                                stroke: color,
                                fill: color,
                                snapTooltip: true,
                                tooltip:  that.generalForm.chartParameters.balloons ? am5.Tooltip.new(that.$options.root, {
                                                                    labelText: "[bold]{name}[/]: {valueY}"
                                                                }) : null
                            }));

                            series.events.once("datavalidated", function (/*ev*/) {
                                // console.log("datavalidated event triggered. ev=", ev);
                               // that.initialZoom();
                               resolve("datavalidated");
                            });

                            if (typeof scrollbar !== 'undefined') {
                                sbSeries = sbChart.series.push(am5xy.LineSeries.new(that.$options.root, {
                                    valueXField: "date",
                                    valueYField: years,
                                    xAxis: sbChart.xAxes.getIndex(0),
                                    yAxis: sbChart.yAxes.getIndex(0),
                                    strokeOpacity: 0,
                                    fillOpacity: 0
                                }));
                            }

                            this.series = series;
                            this._handleLegend(series);
                        }

                        // Don't forget inherited stuff
                        super._afterNew();
                    }

                    _beforeChanged() {

                        if (this.isDirty("margin")) {
                            this.markDataDirty();
                        }

                        // Don't forget inherited stuff
                        super._beforeChanged();
                    }

                    prepareData() {
                        // console.log("prepareData() starting.");
                        // Setting up data
                        this.series.data.setAll(that.$options.seasonalDataArray);
                        if (typeof sbSeries !== 'undefined') {
                            sbSeries.data.setAll(that.$options.seasonalDataArray);
                        }
                    }

                    _dispose() {
                        console.log("_dispose() starting.");
                        this.series.dispose();
                        if (typeof sbSeries !== 'undefined') {
                            // console.log("testing. ");
                            sbSeries.dispose();
                        }
                        super._dispose();
                    }
                }

                // Add indicator

                let stockChart = am5.registry.entitiesById["stockChart-" + this.namespace];

                console.log("that.namespace=", that.namespace);
                stockChart.indicators.push(SeasonalIndicator.new(this.$options.root, {
                    stockChart: stockChart,
                    stockSeries: stockChart.get("stockSeries"),
                    volumeSeries: stockChart.get("volumeSeries"),
                    id: years + "-y_seasonalIndicator-" + that.namespace
                            // legend: valueLegend
                }));
            }

           // bus.$emit("setChartHeight");
        });
        }
    }
};

export default seasonals;