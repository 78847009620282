import * as am5 from "@amcharts/amcharts5";
//import * as am5xy from "@amcharts/amcharts5/xy";
import { getSpreadExpiration, getSpreadFND } from '@/js/main';
import moment from "moment";

const expirationGuide = {
    fndDataItem: {}, // <-- non-reactive property
    expirationDataItem: {}, // <-- non-reactive property
    rangeDataItem: {}, // <-- non-reactive property
    mounted() {
        // console.log("mixins/expirationGuide.js mounted() starting. this.namespace=", this.namespace);
    },
    methods: {
        addExpirationGuide() {
            console.log("addExpirationGuide() starting.");

            let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
            let spread = generalForm.selected[0];

            let fnd = getSpreadFND(spread);
            // console.log("fnd=", fnd);
            let fndObject = moment(fnd, "YYYY-MM-DD").add(12, 'hours');
            // console.log("fndObject=", fndObject);

            let expiration = getSpreadExpiration(spread);
             console.log("expiration=", expiration);
            let expirationObject = moment(expiration, "YYYYMMDD").add(12, 'hours');
            // console.log("expirationObject=", expirationObject);

            let dateAxis = am5.registry.entitiesById["dateAxis-" + this.namespace];

            let fndTest = moment(getSpreadFND(spread), "YYYYMMDD").add(60, 'days').isAfter(moment(expiration, "YYYYMMDD")); // This is in response to very bad FND data.
            // console.log("fndTest=", fndTest);
            let showFND = (!isNaN(getSpreadFND(spread)) && getSpreadFND(spread) <= expiration && fndTest);
            // console.log("showFND=", showFND);

            if (showFND) {
                this.$options.fndDataItem = dateAxis.makeDataItem({ value: fndObject.valueOf() });
                dateAxis.createAxisRange(this.$options.fndDataItem);

                this.$options.fndDataItem.get("grid").setAll({
                    strokeOpacity: 1,
                    stroke: "black",
                    strokeWidth: 2,
                    strokeDasharray: [3, 3]
                });

                this.$options.fndDataItem.get("label").setAll({
                    // fill: "black",
                    text: "FND: " + fndObject.format("MMM D, YYYY"),
                    inside: true,
                    fontSize: 11,
                    fontWeight: "400",
                    rotation: 90,
                    centerX: am5.p100,
                    centerY: am5.p100,
                    location: 0,
                    paddingBottom: -15,
                    paddingRight: 15
                });
            }

            // console.log("expirationObject.valueOf()=", expirationObject.valueOf());
            this.$options.expirationDataItem = dateAxis.makeDataItem({ value: expirationObject.valueOf(), affectsMinMax: true });
            // console.log("this.$options.expirationDataItem=", this.$options.expirationDataItem);
            dateAxis.createAxisRange(this.$options.expirationDataItem);

            this.$options.expirationDataItem.get("grid").setAll({
                strokeOpacity: 1,
                stroke: "black",
                strokeWidth: 2,
                affectsMinMax: true
            });

            this.$options.expirationDataItem.get("label").setAll({
                text: "EXP: " + expirationObject.format("MMM D, YYYY"),
                inside: true,
                affectsMinMax: true,
                fontSize: 11,
                fontWeight: "400",
                rotation: 90,
                centerX: am5.p100,
                centerY: am5.p100,
                location: 0,
                paddingBottom: -14,
                paddingRight: 15
            });

            if (this.program !== "TradeMaps") {
                let sbDateAxis = am5.registry.entitiesById["sbDateAxis-" + this.namespace];
                sbDateAxis.createAxisRange(sbDateAxis.makeDataItem({ value: expirationObject.valueOf(), affectsMinMax: true }));
            }

            if (showFND) {
                this.$options.rangeDataItem = dateAxis.makeDataItem({ value: fndObject.valueOf(), endValue: expirationObject.valueOf(), affectsMinMax: true });
                dateAxis.createAxisRange(this.$options.rangeDataItem);
                this.$options.rangeDataItem.get("axisFill").setAll({ fill: "LightGray", opacity: 0.6, visible: true });
                this.$options.rangeDataItem.get("grid").set("forceHidden", true);
            }
        },
    }
};

export default expirationGuide;