import { spreadDigits, spreadUnits } from '@/js/main';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";

import moment from 'moment';

const profitLossNew = {
    profitLossEvent: {}, // <-- non-reactive property
    profitLossLabel: {},
    
    color: "black", // <-- non-reactive property
    drawingSeries: {}, // <-- non-reactive property
    ranges: [], // <-- non-reactive property
    
    mounted() {
       // console.log("mixins/profitLossNew.js mounted() starting. this.namespace=", this.namespace, " this.addProfitLoss=", this.addProfitLoss);
        // this.addProfitLoss ? this.$options.chart.map.getKey("profit-loss-label").text = this.visible ? "Click open and close on chart." : "" : this.clearProfitLoss();
    },
    beforeDestroy() {
       // console.log("mixins/profitLossNew.js beforeDestroy() starting. this.namespace=", this.namespace);
        let mainPanel = am5.registry.entitiesById["mainPanel-" + this.namespace];
        if(typeof mainPanel !== 'undefined'){
            mainPanel.plotContainer.events.off("click");
        }
    },
    computed: {
        addProfitLoss() {
            return this.$store.state[this.namespace].addProfitLoss;
        },
        buySell() {
            return this.$store.state[this.namespace].buySell;
        },
        instrument() {
            return this.$store.state[this.namespace].instrument;
        }
    },
    watch: {
        addProfitLoss: function (visible) {
            console.log("watch visible=", visible, " this.namespace=", this.namespace);
            if (!visible) {
                this.clearProfitLoss();
            } else {  //if(typeof this.$options.chart.map.getKey("profit-loss-label") !== 'undefined') {
                // this.$options.chart.map.getKey("profit-loss-label").text = "Click open and close on chart.";
                this.addClickListener();
            }
        },
        buySell: function () {
            // console.log("watch buySell = ", buySell);
            if (this.addProfitLoss) {
                this.clearProfitLoss();
                this.addClickListener();
                this.createEquation();
            }
        }
    },
    methods: {
        clearProfitLoss() {
            console.log("clearProfitLoss() starting. this.namespace=", this.namespace);
            let mainPanel = am5.registry.entitiesById["mainPanel-" + this.namespace];
            mainPanel.plotContainer.events.off("click", this.clickHandler);
            let valueAxis = am5.registry.entitiesById["valueAxis-" + this.namespace];

            let length = this.$options.drawingSeries._data._values.length;
            if (length === 2) {
                this.$options.drawingSeries.data.removeIndex(1);
            }
            console.log("this.$options.drawingSeries.data=", this.$options.drawingSeries.data);
            if (length > 0) {
                this.$options.drawingSeries.data.removeIndex(0);
            }

            valueAxis.axisRanges.removeValue(this.$options.ranges[0]);
            valueAxis.axisRanges.removeValue(this.$options.ranges[1]);
            this.$options.ranges = [];

            this.removeLabels();
        },
        removeLabels(){
            console.log("removeLabels() starting.");
            let instructionLabel = am5.registry.entitiesById["instructionLabel-" + this.namespace];
            if (typeof instructionLabel !== 'undefined') {
                instructionLabel.removeAll();
            }
            let equationLabel = am5.registry.entitiesById["equationLabel-" + this.namespace];
            if (typeof equationLabel !== 'undefined') {
                equationLabel.removeAll();
            }
        },
        addClickListener() {
            console.log("addClickListener() starting. this.namespace=", this.namespace);

            let dateAxis = am5.registry.entitiesById["dateAxis-" + this.namespace];
            let valueAxis = am5.registry.entitiesById["valueAxis-" + this.namespace];
            let mainPanel = am5.registry.entitiesById["mainPanel-" + this.namespace];
            let that = this;

            this.loadInstructionLabel();

            this.$options.drawingSeries = mainPanel.series.push(am5xy.LineSeries.new(this.$options.root, {
                name: "Series",
                xAxis: dateAxis,
                yAxis: valueAxis,
                valueYField: "value",
                valueXField: "date",
                stroke: that.$options.color
            }));

            this.$options.drawingSeries.strokes.template.setAll({
                strokeWidth: 1,
                strokeDasharray: [1.5, 1.5]
            });

            // Bullet
            this.$options.drawingSeries.bullets.push(function () {
                let bulletCircle = am5.Circle.new(that.$options.root, {
                    radius: 3,
                    fill: that.$options.color
                });
                return am5.Bullet.new(that.$options.root, {
                    sprite: bulletCircle
                });
            });

            mainPanel.plotContainer.events.on("click", this.clickHandler);
        },
        clickHandler(ev) {
            console.log("clickHandler() starting. ev=", ev);
            let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
            let digits = spreadDigits(generalForm.selected[0], generalForm.instrument);
            console.log("digits=", digits);

            let dateAxis = am5.registry.entitiesById["dateAxis-" + this.namespace];
            let mainPanel = am5.registry.entitiesById["mainPanel-" + this.namespace];
            let valueAxis = am5.registry.entitiesById["valueAxis-" + this.namespace];

            let point = mainPanel.plotContainer.toLocal(ev.point);
            let date = dateAxis.positionToValue(dateAxis.coordinateToPosition(point.x));
            let value = valueAxis.positionToValue(valueAxis.coordinateToPosition(point.y)).toFixed(digits);
            console.log("date=", date, " value=", value);

            let readableDate = moment.utc(date).format("YYYYMMDD");
            console.log("readableDate=", readableDate);

            console.log("this.$options.drawingSeries=", this.$options.drawingSeries);
            let length = this.$options.drawingSeries._data._values.length;
            console.log("length=", length);

            if (length < 2) {
                this.$options.drawingSeries.data.push({
                    date: date,
                    value: value
                });

                if (length === 1) {
                    let equation = this.createEquation();
                    console.log("equation=", equation);

                    this.loadEquationLabel(equation);
                }

                let rangeDataItem = valueAxis.makeDataItem({value: value});
                this.$options.ranges.push(valueAxis.createAxisRange(rangeDataItem));

                rangeDataItem.get("grid").setAll({
                    strokeOpacity: 1,
                    stroke: this.$options.color,
                    strokeWidth: 1,
                    strokeDasharray: [2, 2]
                });

                rangeDataItem.get("label").setAll({
                    inside: true,
                    text: value,
                    fontSize: 11,
                    fontWeight: "400",
                    rotation: 0,
                    paddingBottom: 2,
                    paddingLeft: -500,

                    location: 0,
                    visible: true,
                    centerX: 0,
                    centerY: am5.p100
                });
            } else {
                console.log("Removing P/L series.");
                this.$options.drawingSeries.data.removeIndex(1);
                this.$options.drawingSeries.data.removeIndex(0);

                valueAxis.axisRanges.removeValue(this.$options.ranges[0]);
                valueAxis.axisRanges.removeValue(this.$options.ranges[1]);
                this.$options.ranges = [];

                this.removeLabels();
                this.loadInstructionLabel();
            }
        },
        loadEquationLabel(equation) {
            console.log("loadEquationLabel() starting.");
            this.removeLabels();

            let equationLabel = am5.registry.entitiesById["equationLabel-" + this.namespace];
            if (typeof equationLabel !== 'undefined') {
                equationLabel.set({
                    id: "equationLabel-" + this.namespace,
                    text: equation,
                    fontSize: 11,
                    fontWeight: "normal",
                    textAlign: "center",
                    x: am5.percent(46),
                    centerX: am5.percent(50),
                    paddingTop: 2,
                    paddingBottom: 0, 
                    color: "red"
                });
            }else{

            let mainPanel = am5.registry.entitiesById["mainPanel-" + this.namespace];
            mainPanel.children.unshift(am5.Label.new(this.$options.root, {
                id: "equationLabel-" + this.namespace,
                text: equation,
                fontSize: 11,
                fontWeight: "normal",
                textAlign: "center",
                x: am5.percent(46),
                centerX: am5.percent(50),
                paddingTop: 2,
                paddingBottom: 0
            }));
        }
        },
        loadInstructionLabel() {
            console.log("loadInstructionLabel() starting.");
            this.removeLabels();

            let mainPanel = am5.registry.entitiesById["mainPanel-" + this.namespace];
            mainPanel.children.unshift(am5.Label.new(this.$options.root, {
                id: "instructionLabel-" + this.namespace,
                text: "Click open and close on chart.",
                fontSize: 11,
                fontWeight: "normal",
                textAlign: "center",
                x: am5.percent(46),
                centerX: am5.percent(50),
                paddingTop: 2,
                paddingBottom: 0
            }));
        },
        createEquation() {
            console.log("createEquation() starting.");
            let selected = this.$store.state[this.namespace].selected[0];
            //  console.log("selected=", selected);
            // console.log("this.parsedData=", this.parsedData);

            // let digits = spreadDigits(selected, this.instrument);
            // console.log("digits=", digits);
            let units = spreadUnits(selected, this.instrument);
            console.log("units=", units);

            if (typeof this.$options.profitLossLabel === 'undefined') {
                // this.$options.profitLossLabel = this.chart.createChild(am4core.Label);
            }

            console.log("this.$options.drawingSeries._data._values=", this.$options.drawingSeries._data._values);
            let values = JSON.parse(JSON.stringify(this.$options.drawingSeries._data._values))
                        .sort((a, b) => (a.date - b.date))
                        .map(x => parseFloat(x.value));
            let diff = values[1] - values[0];
            console.log("values=", JSON.parse(JSON.stringify(values)));

            let buySellFactor = this.buySell === "buy" ? 1 : -1;
            //  console.log("buySellFactor=", buySellFactor);

            let rhsColor = buySellFactor * diff > 0 ? "black" : am5.color(0xff0000);
            let unitMoveColor = this.buySell === "buy" ? "black" : am5.color(0xff0000);
            console.log("units=", units);

            let leftParensForNegs = values[0] < 0 ? "(" : "";
            let rightParensForNegs = values[0] < 0 ? ")" : "";
            this.$options.profitLossLabel.fill = "black";

            let profitLossLabelText;
            if (units !== "USD") {
                profitLossLabelText = "P/L = (" + values[1] + " - " + leftParensForNegs + values[0] + rightParensForNegs + ") * [" + unitMoveColor + "]$" +
                        buySellFactor * this.$store.state[this.namespace].unitMove[0] + " = [" + rhsColor + "]" + new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD'
                }).format(buySellFactor * this.$store.state[this.namespace].unitMove[0] * diff) + "[/]";
            } else {
                profitLossLabelText = "P/L = " + new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD'
                }).format(values[1]) + " - " + leftParensForNegs + new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD'
                }).format(values[0]) + rightParensForNegs + " = [" + rhsColor + "]" + new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD'
                }).format(diff) + "[/]";
            }
            this.$options.profitLossLabel.text = profitLossLabelText;
            console.log("profitLossLabelText=", profitLossLabelText);
            return profitLossLabelText;
        }
    }
};

export default profitLossNew;
