import moment from 'moment';

const normalization = {
    mounted() {
        //console.log("mixins/normalization.js mounted() starting. this.namespace=", this.namespace);
    },
    methods: {
        removeNormalization(chart) {
            // console.log("removeNormalization() starting. chart=", chart);
            let dateAxis = chart.map.getKey('date-axis');
            dateAxis.axisRanges.clear();
        },
        addNormalization(chart) {
            if (this.$store.state[this.namespace].normalization === "on"){
                console.log("addNormalization() starting.");
                console.log("this.parsedData.normalizationDate=", this.parsedData.normalizationDate);
                // this.removeLevels(this.chart);

                let generalForm = this.$store.state[this.namespace];
                console.log("generalForm=", generalForm);

                if (generalForm.aligned) {
                    let dateAxis = chart.map.getKey('date-axis');
                    // console.log("dateAxis=", dateAxis);

                    let open = dateAxis.axisRanges.create();
                    open.id = "open-range";

                    moment(this.$store.state[this.namespace].open, "YYYYMMDD").add(12, 'hours');
                    let openDateObj = moment(this.parsedData.normalizationDate, "YYYYMMDD").add(12, 'hours');
                    open.date = openDateObj.toDate();

                    open.grid.stroke = "red";
                    open.grid.strokeWidth = 2;
                    open.grid.strokeOpacity = 1;
                    open.grid.strokeWidth = 2;
                    open.grid.strokeDasharray = "2,2";

                    let openLabel = "normalization date: \n" + moment(openDateObj).format("MMM D, YYYY");
                    open.label.text = openLabel;
                    open.label.fill = "red";
                    open.label.inside = true;
                    open.label.valign = "bottom";
                    open.label.rotation = 0;
                    open.label.verticalCenter = "bottom";
                    open.label.horizontalCenter = "right";
                    open.label.fontSize = 11;
                    open.label.fontWeight = "normal";
                }
            }
            
        }
    }
};

export default normalization;