<template>
  <!--begin::List Widget 8-->
  <div class="card card-custom gutter-b card-stretch">
    <!--begin::Header-->
    <div class="card-header pt-6 border-0" >
      <h3 class="card-title font-weight-bolder text-dark" style="font-size: 1.75rem">La strategia del mese</h3>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body mt-6 pt-0 pb-0">
      <template v-for="(item, i) in list">
        <!--begin::Item-->
        <div class="mb-10" v-bind:key="i">
          <!--begin::Section-->
          <div class="d-flex align-items-center">
            <!--begin::Symbol-->
            <div class="symbol symbol-45 symbol-light mr-5">
              <span class="">
                <inline-svg
                  :src="item.svg"
                  class="align-self-center"
                ></inline-svg>
              </span>
            </div>
            <!--end::Symbol-->
            <!--begin::Text-->
            <div class="d-flex flex-column flex-grow-1">
              <a
                class="font-weight-bold text-dark-75 text-hover-primary mb-1" style="font-size: 1.75rem"
              >
                {{ item.title }}
              </a>
              <span class="text-muted font-weight-bold" style="font-size: 1.2rem">
                {{ item.alt }}
              </span>
            </div>
            <!--end::Text-->
          </div>
          <!--end::Section-->
          <!--begin::Desc-->
          <p class="text-dark-50 m-0 pt-5 font-weight-normal " style="font-size: 18px">
            {{ item.desc }}
          </p>
          <!--end::Desc-->
          <div
          class="p-8"
          style="align-items: center; height: calc(20vh - 100px)"
        >
          <a
            v-on:click="openPortfolio()"
            class="btn btn-primary  py-2 px-4" 
            style="
              margin-left: auto;
              margin-right: auto;
              display: block;
              width: 250px;
              font-size: 18px;
              font-weight: 500"
            >Vai alla strategia</a
          >
        </div>
        </div>
        <!--end::Item-->
      </template>
    </div>
    <!--end::Body-->
  </div>
  <!--end: List Widget 8-->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "widget-8",
  data() {
    return {
      list: [
        {
          title: "LE2023Z",
          desc:
            "Scopri la strategia del mese selezionata dai nostri Top traders ",
          alt: "Granaglie",
          svg: "media/svg/misc/grain.svg"
        }
      ]
    };
  },
  components: {
    
  },
  computed: {
    ...mapGetters(["layoutConfig"])
  }
};
</script>