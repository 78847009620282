import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5stock from "@amcharts/amcharts5/stock";
import { volatility } from '@/js/am4charts-plugins/am4charts.main';
import {spreadDigits} from "@/js/main";
// import { bus } from "@/main";

import moment from "moment";

//let stockSeries;
//let scrollbar;

const seasonals = {
    adjustedSeasonalDataArray: [], // <-- non-reactive property
    mounted() {
      //  console.log("mixins/seasonalsAdjusted.js mounted() starting. this.namespace=", this.namespace);
      //  console.log("this.$options.adjustedSeasonalDataArray=", this.$options.adjustedSeasonalDataArray);
        this.$options.adjustedSeasonalDataArray = [];
    },
    methods: {
        getAdjustedSeasonalData(years) {
            // console.log("getAdjustedSeasonalData() starting. years=", years);

          this.error = null;

            let that = this;
            let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
            let digits = spreadDigits(generalForm.selected[0], "future") + 1;
            // console.log("digits=", digits);

            if (generalForm.seasonals.length > 0) {
                // console.log("that.seasonalDataArray=", JSON.parse(JSON.stringify(that.seasonalDataArray)));

                let mainDataSubset = this.$options.preparedData.map(bar => {
                    return {
                        date: bar.date,
                        readableDate: bar.readableDate,
                        close: bar.close
                    }
                }).filter(x => typeof x.close !== 'undefined');
                // console.log("mainDataSubset=", mainDataSubset);

                let mainDataLastDate = mainDataSubset[mainDataSubset.length - 1].readableDate;
                // console.log("mainDataLastDate=", mainDataLastDate);
                let mainDataStartDate = moment(mainDataLastDate, "YYYYMMDD").subtract(1, "years").format("YYYYMMDD");
                // console.log("mainDataStartDate=", mainDataStartDate);

                let truncatedMainData = mainDataSubset.filter(bar => {
                    //console.log("bar.date=", bar.date);
                    return moment(bar.readableDate, "YYYYMMDD").isAfter(mainDataStartDate);
                })
                // console.log("truncatedMainData=", truncatedMainData);

                let dailyPriceVolatility = volatility(truncatedMainData, "close");
                // console.log("dailyPriceVolatility=", dailyPriceVolatility);

                let seasonalVolatility = volatility(that.$options.seasonalDataArray, years);
                // console.log("seasonalVolatility=", seasonalVolatility);

                let volatilityFactor = dailyPriceVolatility / seasonalVolatility;
                // console.log("volatilityFactor=", volatilityFactor);

                let seasonalDataAdjustedForVolatility = that.$options.seasonalDataArray.map(bar => {
                    return {
                        date: bar.date,
                        readableDate: bar.readableDate,
                        adjustedForVolatilitySeasonal: volatilityFactor * parseFloat(bar[years])
                    }
                });
                // console.log("seasonalDataAdjustedForVolatility=", seasonalDataAdjustedForVolatility);

                let seasonalDataForRegression = seasonalDataAdjustedForVolatility.map(x => {
                    // console.log("\nx.readableDate=", x.readableDate);

                    let mainDataBar = mainDataSubset.find(y => y.readableDate === x.readableDate);
                    // console.log("mainDataBar=", mainDataBar);
                    // console.log("typeof mainDataBar=", typeof mainDataBar);

                    return {
                        date: x.date,
                        readableDate: x.readableDate,
                        [years + '-y_adjusted']: typeof mainDataBar !== 'undefined' ? mainDataBar.close - parseFloat(x.adjustedForVolatilitySeasonal) : null
                    };
                }).filter(x => x[years + '-y_adjusted'] !== null);
                // console.log("seasonalDataForRegression=", JSON.parse(JSON.stringify(seasonalDataForRegression)));

                let deviationArray = seasonalDataForRegression.map(bar => bar[years + '-y_adjusted']).filter(x => !isNaN(x) && x !== null);
               // console.log("deviationArray=", deviationArray);

                if (deviationArray.length > 0) {
                    let deviationAverage = deviationArray.reduce((sum, x) => sum + x) / deviationArray.length;
                    // console.log("deviationAverage=", deviationAverage);

                    //  console.log("that.seasonalDataArray=", that.seasonalDataArray);
                    let adjustedSeasonalData = that.$options.seasonalDataArray.map(bar => {
                        // console.log("bar=", bar);
                        // let seasonalBar = seasonalData.find(x => x.date === moment(bar.date, "YYYYMMDD").format("YYYY-MM-DD"));
                        // console.log("seasonalBar=", seasonalBar);
                        if (typeof bar !== 'undefined' && !isNaN(bar[years])) {
                            return {
                                date: bar.date,
                                readableDate: bar.readableDate,
                                adjustedSeasonal: parseFloat(volatilityFactor * parseFloat(bar[years]) + deviationAverage).toFixed(digits)
                            }
                        } else {
                            return;
                        }
                    }).filter(x => typeof x !== 'undefined');
                   // console.log("adjustedSeasonalData=", adjustedSeasonalData);

                    let processor = am5.DataProcessor.new(that.$options.root, {
                        dateFields: ["date"],
                        dateFormat: "yyyyMMdd",
                        numericFields: ["adjustedSeasonal"]
                    });
                    processor.processMany(adjustedSeasonalData);
                   // console.log("adjustedSeasonalData=", JSON.parse(JSON.stringify(adjustedSeasonalData)));

                   // console.log("this.$options.adjustedSeasonalDataArray=", JSON.parse(JSON.stringify(this.$options.adjustedSeasonalDataArray)));
                    this.$options.adjustedSeasonalDataArray.push({years: years, data: adjustedSeasonalData});
                   // console.log("this.$options.adjustedSeasonalDataArray=", JSON.parse(JSON.stringify(this.$options.adjustedSeasonalDataArray)));

                } else {
                    this.error = "Adjusted seasonals cannot be created until the data overlaps with the corresponding unadjusted seasonal.";
                }
            }
        },
        addAdjustedSeasonals() {
            // console.log("addAdjustedSeasonals() starting.");
            let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
            return new Promise((resolve) => {

            // console.log("am5.registry.entitiesById=", am5.registry.entitiesById);
            // console.log("am5.registry.entitiesById=", { ...am5.registry.entitiesById });

            let that = this;
            generalForm.seasonals.forEach(years => {
                let indicator = am5.registry.entitiesById[years + "-y_adj_seasonalIndicator-" + this.namespace];
                // console.log("indicator=", indicator);
                if (typeof indicator !== 'undefined' && typeof indicator.show !== "undefined") {
                    // console.log("indicator._settings=", indicator._settings);
                    console.log('typeof indicator.show === "undefined"=', typeof indicator.show === "undefined");
                    try {
                        indicator.show();
                    } catch (err) {
                        console.log("err=", err);
                        that.addAdjustedSeasonalIndicator(years).then(addAdjustedSeasonalIndicatorResult => {
                            console.log("addAdjustedSeasonalIndicatorResult=", addAdjustedSeasonalIndicatorResult);
                            resolve("adjusted seasonal added.");
                        });
                    }
                } else {
                    that.addAdjustedSeasonalIndicator(years).then(/*addAdjustedSeasonalIndicatorResult*/ () => {
                       // console.log("addAdjustedSeasonalIndicatorResult=", addAdjustedSeasonalIndicatorResult);
                        resolve("adjusted seasonal added.");
                    });
                }
            });
        });
        },
        removeAdjustedSeasonals(seasonalsToRemove) {
            // console.log("removeAdjustedSeasonals() starting. seasonalsToRemove=", seasonalsToRemove);
            seasonalsToRemove.forEach(years => {
                this.removeAdjustedSeasonalIndicator(years);
            });
            // console.log("this.seasonalDataArray=", JSON.parse(JSON.stringify(this.seasonalDataArray)));
        },
        removeAdjustedSeasonalIndicator(years) {
           // console.log("removeAdjustedSeasonalIndicator() starting. years=", years);

            let indicator = am5.registry.entitiesById[years + "-y_adj_seasonalIndicator-" + this.namespace];
            // console.log("indicator=", indicator);
            if (typeof indicator !== 'undefined') {
                // indicator.hide();
                indicator.dispose();
            }
            
           // bus.$emit("setChartHeight");
        },

        addAdjustedSeasonalIndicator(years) {
            // console.log("addAdjustedSeasonalIndicator() starting. years=", years);
            let that = this;
            return new Promise((resolve) => {

            let seasonalColorOptions = that.$store.getters[that.namespace + "/seasonalOptions"];
            // console.log("seasonalColorOptions=", seasonalColorOptions);

            let colorOption = seasonalColorOptions.find(x => x.value == years);
            // console.log("colorOption=", colorOption);

            if (typeof colorOption !== 'undefined') {
                let color = colorOption.color;

                let sbSeries = am5.registry.entitiesById["sbSeries-" + this.namespace];

                // Create a custom indicator
                // -------------------------------------------------------------------------------

                // Define indicator class
                class AdjustedSeasonalIndicator extends am5stock.Indicator {
                    className = "AdjustedSeasonalIndicator";

                    _afterNew() {

                        // Set default indicator name
                        this._setDefault("name", years + "y-adj_seasonal");

                        // Setting up indicator elements
                        let stockSeries = this.get("stockSeries");
                        let scrollbar = stockSeries.chart.get('scrollbarX')

                        let chart = stockSeries.chart;

                        let sbChart;
                        if (typeof scrollbar !== 'undefined') {
                            sbChart = scrollbar.chart;
                        }

                        if (chart) {
                            // console.log("years=", years);

                            that.getAdjustedSeasonalData(years);
                            // console.log("that.adjustedSeasonalDataArray=", that.adjustedSeasonalDataArray);

                            let series = chart.series.push(am5xy.LineSeries.new(that.$options.root, {
                                valueXField: "date",
                                valueYField: "adjustedSeasonal",
                                groupDataDisabled: true,
                                calculateAggregates: true,
                                xAxis: stockSeries.get("xAxis"),
                                yAxis: stockSeries.get("yAxis"),
                                themeTags: ["indicator"],
                                name: years + "-y adjusted seasonal",
                                legendLabelText: "{name}",
                                legendValueText: years + "-y_adj_seasonal: [bold]{valueY}[/]",
                                legendRangeValueText: "",
                                stroke: color,
                                fill: color,
                                snapTooltip: true,
                                tooltip:  that.generalForm.chartParameters.balloons ? am5.Tooltip.new(that.$options.root, {
                                    labelText: "[bold]{name}[/]: {valueY}"
                                }) : null
                            }));
                            series.strokes.template.setAll({
                                strokeWidth: 1,
                                strokeDasharray: [1.5, 1.5]
                            });

                            series.events.once("datavalidated", function (/*ev*/) {
                                // console.log("datavalidated event triggered. ev=", ev);
                               // that.initialZoom();
                               resolve("datavalidated");
                            });

                            if (typeof scrollbar !== 'undefined') {
                                sbSeries = sbChart.series.push(am5xy.LineSeries.new(that.$options.root, {
                                    valueXField: "date",
                                    valueYField: "adjustedSeasonal",
                                    xAxis: sbChart.xAxes.getIndex(0),
                                    yAxis: sbChart.yAxes.getIndex(0),
                                    strokeOpacity: 0,
                                    fillOpacity: 0
                                }));
                            }

                            this.series = series;
                            this._handleLegend(series);
                        }

                        // Don't forget inherited stuff
                        super._afterNew();
                    }

                    _beforeChanged() {

                        if (this.isDirty("margin")) {
                            this.markDataDirty();
                        }

                        // Don't forget inherited stuff
                        super._beforeChanged();
                    }

                    prepareData() {
                        // console.log("prepareData() starting.");
                        // Setting up data
                        let tempData = that.$options.adjustedSeasonalDataArray.find(x => x.years === years);
                        // console.log("tempData=", tempData);

                        if (typeof tempData !== 'undefined') {
                            this.series.data.setAll(tempData.data);
                            if (typeof sbSeries !== 'undefined') {
                                sbSeries.data.setAll(tempData.data);
                            }
                        }
                    }

                    _dispose() {
                        this.series.dispose();
                        if (typeof sbSeries !== 'undefined') {
                            sbSeries.dispose();
                        }
                        super._dispose();
                    }
                }

                // Add indicator

                let stockChart = am5.registry.entitiesById["stockChart-" + this.namespace];

                stockChart.indicators.push(AdjustedSeasonalIndicator.new(this.$options.root, {
                    stockChart: stockChart,
                    stockSeries: stockChart.get("stockSeries"),
                    volumeSeries: stockChart.get("volumeSeries"),
                    id: years + "-y_adj_seasonalIndicator-" + that.namespace
                            // legend: valueLegend
                }));
            }

           // bus.$emit("setChartHeight");
        });
        }
    }
};

export default seasonals;