(function(){"use strict";try{if(typeof document!="undefined"){var o=document.createElement("style");o.appendChild(document.createTextNode("div.v-dropdown-caller{display:inline-block}div.v-dropdown-caller.v-dropdown-caller--full-width{display:block}div.v-dropdown-container{display:inline-block;margin:0;padding:0;top:0;left:0;border:1px solid #d6d7d7;position:absolute;-webkit-box-sizing:border-box;box-sizing:border-box;background-color:#fff;border-radius:2px;overflow:hidden;z-index:3000;-webkit-box-shadow:0 3px 12px rgba(0,0,0,.2);box-shadow:0 3px 12px #0003;will-change:opacity,transform}div.v-dropdown-container.v-dropdown-embed{position:relative;-webkit-box-shadow:0 1px 6px rgba(0,0,0,.12)!important;box-shadow:0 1px 6px #0000001f!important;z-index:100}div.v-dropdown-container.v-dropdown-no-border{border:0;-webkit-box-shadow:0 3px 8px rgba(0,0,0,.3);box-shadow:0 3px 8px #0000004d}.animate-down-enter,.animate-down-leave-to{-webkit-transform:translateY(-6px);transform:translateY(-6px);opacity:0}.animate-up-enter,.animate-up-leave-to{-webkit-transform:translateY(6px);transform:translateY(6px);opacity:0}.animate-down-enter-active,.animate-down-leave-active,.animate-up-enter-active,.animate-up-leave-active{-webkit-transition:opacity .15s,transform .15s;-webkit-transition:opacity .15s,-webkit-transform .15s;transition:opacity .15s,-webkit-transform .15s;transition:opacity .15s,transform .15s;transition:opacity .15s,transform .15s,-webkit-transform .15s}.animate-down-enter-to,.animate-down-leave,.animate-up-enter-to,.animate-up-leave{-webkit-transform:none;transform:none;opacity:1}")),document.head.appendChild(o)}}catch(t){console.error("vite-plugin-css-injected-by-js",t)}})();
const a = {
  name: "v-dropdown",
  props: {
    /**
     * align with the dropdown layer direction
     */
    align: {
      type: String,
      default: "left"
    },
    /**
     * dropdown layer embedded to page/component
     */
    embed: {
      type: Boolean,
      default: !1
    },
    border: {
      type: Boolean,
      default: !0
    },
    /**
     * mouse right click caller area to display dropdown
     */
    rightClick: {
      type: Boolean,
      default: !1
    },
    /**
     * click caller and display dropdown, the caller click again whether to close dropdown
     */
    toggle: {
      type: Boolean,
      default: !0
    },
    /**
     * manual show / close the dropdown
     */
    manual: {
      type: Boolean,
      default: !1
    },
    disabled: {
      type: Boolean,
      default: !1
    },
    /**
     * open / close dropdown animation
     * true: use default animation
     * false: don't show animation
     * string value: customized animation
     */
    animated: {
      type: [String, Boolean],
      default: !0
    },
    /**
     * the width of drop down menu
     * min-width: 80
     */
    width: Number,
    /**
     * container with
     * false: inline-block
     * true: block
     */
    fullWidth: {
      type: Boolean,
      default: !1
    },
    index: Number,
    namespace: String,
    open: Boolean,
    close: Boolean
  },
  data() {
    return {
      show: !1,
      styleSheet: { top: "", left: "" },
      dropdownClass: "v-dropdown-container",
      dropUp: !1,
      x: null,
      y: null
    };
  },
  computed: {
    animate() {
      return typeof this.animated == "string" ? this.animated : !this.embed && this.animated ? this.dropUp ? "animate-up" : "animate-down" : "";
    }
  },
  render(e) {
    const t = [];
    "caller" in this.$scopedSlots && !this.embed && t.push(this.$scopedSlots.caller()), (typeof this.$slots.caller[0].context.data1 > "u" || this.$slots.caller[0].context.data1.children === null) && this.$slots.caller[0].context.errorMessage === "" && (this.show = !1, this.$emit("show", !1)), t.push(e("transition", {
      props: {
        name: this.animate
      }
    }, [e("div", {
      class: {
        [this.dropdownClass]: !0,
        "v-dropdown-embed": this.embed,
        "v-dropdown-no-border": !this.border
      },
      style: this.styleSheet,
      directives: [{ name: "show", value: this.show }],
      ref: "dropdown",
      on: {
        mousedown: (i) => {
          i.stopPropagation();
        }
      }
    }, this.$slots.default)]));
    let s;
    return s = e("div", {
      class: {
        "v-dropdown-caller": !0,
        "v-dropdown-caller--full-width": this.fullWidth
      },
      on: {
        click: (i) => {
          this.embed || this.rightClick || this.manual || (i.stopPropagation(), this.visible());
        },
        // mouse right button click
        contextmenu: (i) => {
          if (this.embed || this.manual || !this.rightClick)
            return;
          i.stopPropagation(), i.preventDefault();
          const l = this.scrollInfo();
          this.x = i.pageX || i.clientX + l.x, this.y = i.pageY || i.clientY + l.y, this.visible();
        }
      }
    }, t), s;
  },
  methods: {
    visible(e = !1) {
      this.disabled || this.show && !this.toggle && !e || (!this.show && !this.embed && this.$slots.caller && this.adjust(), this.show = !this.show, this.$emit("show", this.show));
    },
    /**
     * the dropdown container outside click handle
     * @param e - MouseEvent
     */
    whole(e) {
      if (this.show) {
        const t = this.eventPath(e).findIndex((s) => s === this.$el) !== -1;
        if (t && !this.toggle && !this.rightClick)
          return;
        (!t || t && this.rightClick) && this.visible(!0);
      }
    },
    /**
     * adjust dropdown display position
     */
    adjust() {
      const e = this.$el.getBoundingClientRect();
      let t = null;
      this.show ? t = this.$refs.dropdown.getBoundingClientRect() : (this.$refs.dropdown.style.visibility = "hidden", this.$refs.dropdown.style.display = "inline-block", t = this.$refs.dropdown.getBoundingClientRect(), this.$refs.dropdown.style.visibility = "visible", this.$refs.dropdown.style.display = "none"), this.adjustTop(e, t), this.styleSheet.left = `${this.adjustLeft(e, t)}px`;
    },
    /**
     * calculation direction and top axis
     * @param pos
     * @param menu
     */
    adjustTop(e, t) {
      const i = window.pageYOffset, l = document.documentElement.clientHeight, o = this.rightClick ? this.y : e.top + i;
      let d = this.rightClick ? this.y : e.top + e.height + 5 + i, n = !1, h = !1, r = !1;
      d + t.height > i + l && (n = !0), o - 5 - t.height < i && (h = !0), !h && n && (d = o - 5 - t.height, r = !0), this.dropUp = r, this.styleSheet.top = `${d}px`;
    },
    adjustLeft(e, t) {
      const s = window.pageXOffset, i = document.documentElement.clientWidth, l = this.rightClick ? 0 : e.width, o = this.rightClick ? this.x : e.left + s, d = o + l / 2 - t.width / 2, n = o + l - t.width;
      switch (this.align) {
        case "left":
          return o + t.width > s + i ? n : o;
        case "center":
          return d + t.width > s + i ? n : n < s ? o : d;
        case "right":
          return n < s ? o : n;
      }
    },
    scrollInfo() {
      const e = window.pageXOffset !== void 0, t = (document.compatMode || "") === "CSS1Compat";
      return {
        x: e ? window.pageXOffset : t ? document.documentElement.scrollLeft : document.body.scrollLeft,
        y: e ? window.pageYOffset : t ? document.documentElement.scrollTop : document.body.scrollTop
      };
    },
    /**
     * returns the event’s path which is an array of the objects on which listeners will be invoked
     * @param e - MouseEvent
     * @returns {Array|EventTarget[]|*}
     */
    eventPath(e) {
      if ("composedPath" in e)
        return e.composedPath();
      if ("path" in e)
        return e.path;
      const t = [];
      let s = e.target;
      for (; s; )
        t.push(s), s = s.parentElement;
      return t.indexOf(window) === -1 && t.indexOf(document) === -1 && t.push(document), t.indexOf(window) === -1 && t.push(window), t;
    }
  },
  mounted() {
    this.width && (this.styleSheet.width = this.width + "px"), this.embed ? this.visible() : (document.body.appendChild(this.$refs.dropdown), document.body.addEventListener("mousedown", this.whole));
  },
  beforeDestroy() {
    this.embed || (document.body.removeEventListener("mousedown", this.whole), this.$refs.dropdown.remove());
  },
  destroyed() {
    this.embed || this.$el.remove();
  },
  watch: {
    open() {
      this.adjust(), this.show = !0, this.$emit("show", !0);
    },
    close(e) {
      e && (this.adjust(), this.show = !1, this.$emit("show", !1));
    }
  }
};
export {
  a as Dropdown,
  a as default
};
