function removeNaNs(array, valueFieldName) {
    // console.log("removeNaNs() starting."); // This also removes weekends and days without data.
    //  console.log("valueFieldName=", valueFieldName);
    console.log("array=", array);
    let fields = Object.keys(array[0]);
    //  console.log("fields=", fields);
    if (typeof valueFieldName === 'undefined' || fields.indexOf(valueFieldName) === -1) {
        valueFieldName = "close";
    }
    // console.log("valueFieldName=", valueFieldName);

    let returnArray = array.filter(x => !isNaN(x[valueFieldName]));
    //let nAnArray = array.filter(x => isNaN(x[valueFieldName]));
    // console.log("nAnArray=", nAnArray);
    // console.log("returnArray=", returnArray);
    return returnArray;
}

function sd(objectArray, sdLength, valueFieldName) {
    // console.log("sd() starting.");
    let fields = Object.keys(objectArray[0]);
    if (typeof valueFieldName === 'undefined' || fields.indexOf(valueFieldName) === -1) {
        valueFieldName = "close";
    }
    //  console.log("valueFieldName=", valueFieldName);

    let mainDataPlusIndicators = sma(objectArray, sdLength, valueFieldName);
    // console.log("mainDataPlusIndicators=", mainDataPlusIndicators);

    for (var j = 0; j < mainDataPlusIndicators.length; j++) {
        let obj = objectArray[j];
        let sd = 0;
        if (j > sdLength) {
            let avg = mainDataPlusIndicators[j]["sma_" + sdLength];
            let sumOfSquareDiffs = 0;
            for (var n = 0; n < sdLength; n++) {
                let index = j - n;
                // console.log("index=", index);
                let close = mainDataPlusIndicators[index][valueFieldName];
                // console.log("valueFieldName=", valueFieldName, " mainDataPlusIndicators[" + index + "][valueFieldName]=", mainDataPlusIndicators[index][valueFieldName]);
                let diff = close - avg;
                sumOfSquareDiffs = sumOfSquareDiffs + diff ** 2;
            }
            sd = Math.sqrt(sumOfSquareDiffs / sdLength);
        }
        obj["sd_" + sdLength] = sd;
    }
    return mainDataPlusIndicators;
}


function md(objectArray, mdLength, valueFieldName) {
    //console.log("md() starting.");
    let fields = Object.keys(objectArray[0]);
    if (fields.indexOf(valueFieldName) == -1) {
        valueFieldName = "close";
    }
    // console.log("valueFieldName=", valueFieldName);

    let mdSeries = sma(objectArray, mdLength, valueFieldName);
    // console.log("mdSeries=", mdSeries);

    for (var j = 0; j < mdSeries.length; j++) {
        let obj = objectArray[j];
        let md = 0;
        if (j >= mdLength - 1) {

            let avg = mdSeries[j]["sma_" + mdLength];
            let sumOfAbsoluteDiffs = 0;
            for (var n = 0; n < mdLength; n++) {
                let index = j - n;
                // console.log("index=", index);
                let close = mdSeries[index][valueFieldName];
                // console.log("valueFieldName=", valueFieldName, " mdSeries[" + index + "][valueFieldName]=", mdSeries[index][valueFieldName]);
                let diff = Math.abs(close - avg);
                sumOfAbsoluteDiffs = sumOfAbsoluteDiffs + diff;
            }
            md = sumOfAbsoluteDiffs / mdLength;
            obj[valueFieldName + "_md_" + mdLength] = md;
        }
    }
    return mdSeries;
}

function sma(objectArray, smaLength, valueFieldName) {
    // console.log("sma() starting.");
    // console.log("objectArray=", objectArray);
    // console.log("smaLength=", smaLength);
    // console.log("valueFieldName=", valueFieldName);

    let returnArray = [];
    let tempSma = parseFloat(objectArray[0][valueFieldName]);
    for (var j = 1; j < objectArray.length; j++) {
        let obj = objectArray[j];
        if (j <= smaLength) {
            let close = parseFloat(obj[valueFieldName]);
            // console.log("close=", close);
            tempSma = (tempSma * (j - 1) + close) / j;
        } else {
            tempSma = tempSma + (parseFloat(obj[valueFieldName]) - parseFloat(objectArray[j - smaLength][valueFieldName])) / smaLength;
        }
        obj["sma_" + smaLength] = tempSma;
        // console.log("tempSma=", tempSma);
        // console.log("obj=", obj);
        returnArray.push(obj);
    }
    returnArray.unshift(objectArray[0]);
    // console.log("returnArray=", returnArray);
    return returnArray;
}

function ema(objectArray, emaLength, valueFieldName) {
    //  console.log("ema() starting.");
    // console.log("valueFieldName=", valueFieldName);
    //  console.log("objectArray=", objectArray);

    let k = 2 / (emaLength + 1);
    let returnArray = [];
    let tempEma = parseFloat(objectArray[0][valueFieldName]);
    for (var j = 1; j < objectArray.length; j++) {
        let obj = objectArray[j];
        let close = parseFloat(obj[valueFieldName]);
        tempEma = (close - tempEma) * k + tempEma;
        obj["ema_" + emaLength] = tempEma;
        returnArray.push(obj);
    }
    // console.log("returnArray=", returnArray);
    return returnArray;
}

function volatility(objectArray, field) {
   // console.log("volatility() starting. field=", field, " objectArray=", JSON.parse(JSON.stringify(objectArray)));

    let valueArray = objectArray.map(bar => parseFloat(bar[field]));
   // console.log("valueArray=", JSON.parse(JSON.stringify(valueArray)));

    let filteredValueArray = valueArray.filter(x => {
        let test = x !== null && !isNaN(x);
        // console.log("x=", x, " x !== null=", test);
        return test;
    });
   // console.log("filteredValueArray=", JSON.parse(JSON.stringify(filteredValueArray)));

    let length = filteredValueArray.length;
   // console.log("length=", length);

    if (length > 0) {
        let avg = filteredValueArray.reduce((sum, x) => sum + x) / length;
        // console.log("avg=", avg);

        let squareOfDeviationsArray = filteredValueArray.map(value => (value - avg) ** 2);
        // console.log("squareOfDeviationsArray=", squareOfDeviationsArray);

        let variance = squareOfDeviationsArray.reduce((sum, x) => sum + x) / length;
        // console.log("variance=", variance);

        return Math.sqrt(variance);
    } else {
        return null;
    }
}

export { removeNaNs, md, sd, sma, ema, volatility };