import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5stock from "@amcharts/amcharts5/stock";
import axios from "axios";
import {contractNameDecomposer, getSpreadExpiration} from '@/js/main';
import moment from "moment";

export default {
    mounted() {
        //console.log("mixins/cotNew.js mounted() starting. this.namespace=", this.namespace);
    },
    beforeDestroy() {
        console.log("mixins/cotNew.js beforeDestroy() starting. this.namespace=", this.namespace);
        let cotPanel = am5.registry.entitiesById["cotPanel-" + this.namespace];
        if(typeof cotPanel !== 'undefined'){
            cotPanel.events.off("closed");
        }
    },
    computed: {
        addCOTPanel() {
            return this.$store.state[this.namespace].addCOTPanel;
        }
    },
    watch: {
        addCOTPanel(addCOTPanel) {
            console.log("watch addCOTPanel=", addCOTPanel);
            if (this.addCOTPanel) {
                this.addCotIndicator();
            } else {
                this.removeCotIndicator();
            }
        }
    },
    methods: {
        getCotData() {
           // console.log("getCotData() starting.");
            let that = this;

            return new Promise((resolve) => {
                let commodity = contractNameDecomposer(that.generalForm.sampleContract[0]).commoditySymbol;
                let url = "https://storage.googleapis.com/authentication-f0d7e.appspot.com/cot/" + commodity + ".txt";

                let firstDate = parseInt(that.$options.preparedData[0].readableDate);
               // console.log("firstDate=", firstDate);

                let spread = that.generalForm.selected[0];
            
                let expiration = getSpreadExpiration(spread);
               // console.log("expiration=", expiration);

                let lastDate = expiration; // parseInt(that.preparedData[that.preparedData.length - 1].readableDate);
               // console.log("lastDate=", lastDate);

                axios.get(url)
                        .then(apiResponse => {
                            that.$store.commit('decrementNumberOfAjaxCalls');
                            // console.log("apiResponse=", apiResponse);
                            let cotData = apiResponse.data.filter(bar => parseInt(bar.date) >= firstDate && parseInt(bar.date) <= lastDate);
                           // console.log("cotData=", cotData);
                           // let lastCotDate = cotData[cotData.length - 1].date;
                           // console.log("lastCotDate=", lastCotDate);

                            cotData.forEach(bar => {
                                bar.date = bar.date.toString();
                                bar.readableDate = bar.date.replace(/-/g, "");
                            });

                            
                            let previousBar;
                            that.$options.preparedData.forEach(bar => {
                               // bar.date = bar.date.toString();
                               // bar.readableDate = bar.date.replace(/-/g, "");

                                let cotDataBar = cotData.find(x => {
                                   // console.log("x.date=", x.date, " parseInt(bar.readableDate)=", parseInt(bar.readableDate));
                                    return x.date == parseInt(bar.readableDate);
                                });
                               // console.log("cotDataBar=", cotDataBar, " previousBar=", previousBar);

                                if(typeof cotDataBar !== 'undefined'){
                                    bar.com = cotDataBar.com;
                                    bar.noncom = cotDataBar.noncom;
                                    bar.small = cotDataBar.small;

                                    previousBar = bar;
                                } else if(typeof previousBar !== 'undefined'){
                                    bar.com = previousBar.com;
                                    bar.noncom = previousBar.noncom;
                                    bar.small = previousBar.small;
                                }        
                               // return bar;
                            }); 

                            resolve("data ready.");
                        });
            });
        },
        removeCotIndicator() {
            console.log("removeCotIndicator() starting.");
            let cotPanel = am5.registry.entitiesById["cotPanel-" + this.namespace];
            cotPanel.events.off("closed");
           // console.log("cotPanel=", cotPanel);
           if(typeof cotPanel !== 'undefined'){
              cotPanel.close();
           }
        },
        addCotIndicator() {
           // console.log("addCotIndicator() starting. this.namespace=", this.namespace);
            let that = this;
            // let series;

            this.getCotData().then((/*result*/) => {
               // console.log("result=", result);

                let stockChart = am5.registry.entitiesById["stockChart-" + that.namespace];

                let cotPanel = stockChart.panels.push(am5stock.StockPanel.new(that.$options.root, {
                            wheelY: "zoomX",
                            panX: true,
                            panY: true,
                            id: "cotPanel-" + that.namespace,
                            height: am5.percent(30)
                        }));

                cotPanel.events.on("closed", function (/*event*/) {
                   // console.log("event=", event);
                    that.$store.commit(that.namespace + "/setAddCOTPanel", false);
                });

                var tooltip = am5.Tooltip.new(that.$options.root, {});

                cotPanel.plotContainer.set("tooltipPosition", "pointer");
                cotPanel.plotContainer.set("tooltipText", " ");
                cotPanel.plotContainer.set("tooltip", tooltip);

               // console.log("cotPanel.series=", cotPanel.series);

                tooltip.label.adapters.add("text", function(text) {
                    let i=0;
                    cotPanel.series.each(function(series) {
                        let tooltipDataItem = series.get("tooltipDataItem");
                       // console.log("i=", i);
                        if (tooltipDataItem) {
                          if (i != 0) {
                             text += "\n";
                          }else{
                            text = "";
                          }
                          text += '[' + series.get("stroke").toString() + ']●[bold width:90px fontSize:11px]' + series.get("name")+ '[/]' + ':  [' + series.get("stroke").toString() + ' fontSize:11px]' + tooltipDataItem.get("valueY") + '[/]';
                        }
                        i++;
                      });
                    return text;
                  });

                let cotAxis = cotPanel.yAxes.push(am5xy.ValueAxis.new(that.$options.root, {
                    renderer: am5xy.AxisRendererY.new(that.$options.root, {
                        inside: true
                    }),
                    tooltip: am5.Tooltip.new(that.$options.root, {animationDuration: 200}),
                    numberFormat: "#,###",
                    //extraTooltipPrecision: 2
                }));

                cotAxis.get("renderer").labels.template.setAll({
                    centerY: am5.p100,
                    fill: am5.color(0x888888),
                    maxPosition: 0.99
                });

                let cotDateAxis = cotPanel.xAxes.push(
                    am5xy.DateAxis.new(that.$options.root, {
                       // id: "dateAxis-" + that.namespace,
                        baseInterval: {
                            timeUnit: "day",
                            count: 1,
                        },
                        renderer: am5xy.AxisRendererX.new(that.$options.root, {
                            pan: "zoom",
                            minorGridEnabled: true,
                        }),
                        tooltip: am5.Tooltip.new(that.$options.root, {}),
                       // start: 0.9,
                    }));

                    
                 let spread = that.generalForm.selected[0];
     
                 let expiration = getSpreadExpiration(spread);
                // console.log("expiration=", expiration);
                 let expirationObject = moment(expiration, "YYYYMMDD").add(12, 'hours');
                // console.log("expirationObject=", expirationObject);
                // console.log("expirationObject.valueOf()=", expirationObject.valueOf());

                 cotDateAxis.createAxisRange(
                    cotDateAxis.makeDataItem({ value: expirationObject.valueOf(), affectsMinMax: true })
                );
               
                let comSeries = cotPanel.series.push(am5xy.StepLineSeries.new(that.$options.root, {
                    name: "commercial",
                    valueXField: "date",
                    valueYField: "com",
                    calculateAggregates: true,
                    xAxis: cotDateAxis,
                    yAxis: cotAxis,
                    legendValueText: "{valueY}",
                    stroke: am5.color(0xff0000),
                    fill: am5.color(0xff0000),
                    snapTooltip: true,
                   // tooltip: am5.Tooltip.new(that.$options.root, {labelText: "[bold]{name}[/]: {valueY}"})
                }));

                let noncomSeries = cotPanel.series.push(am5xy.StepLineSeries.new(that.$options.root, {
                    name: "noncommercial",
                    valueXField: "date",
                    valueYField: "noncom",
                    calculateAggregates: true,
                    xAxis: cotDateAxis,
                    yAxis: cotAxis,
                    legendValueText: "{valueY}",
                    stroke: am5.color(0x0000ff),
                    fill: am5.color(0x0000ff),
                    snapTooltip: true,
                  //  tooltip: am5.Tooltip.new(that.$options.root, {labelText: "[bold]{name}[/]: {valueY}"})
                }));

                let smallSeries = cotPanel.series.push(am5xy.StepLineSeries.new(that.$options.root, {
                    name: "small traders",
                    valueXField: "date",
                    valueYField: "small",
                    calculateAggregates: true,
                    xAxis: cotDateAxis,
                    yAxis: cotAxis,
                    legendValueText: "{valueY}",
                    stroke: am5.color(0x000000),
                    fill: am5.color(0x000000 ),
                    snapTooltip: true,
                   // tooltip: am5.Tooltip.new(that.$options.root, {labelText: "[bold]{name}[/]: {valueY}"})
                }));

                // Add cursor(s)
                // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
                cotPanel.set("cursor", am5xy.XYCursor.new(that.$options.root, {
                    yAxis: cotAxis,
                    xAxis: cotDateAxis,
                    snapToSeries: [comSeries, noncomSeries, smallSeries],
                    snapToSeriesBy: "y!"
                }));

                let processor = am5.DataProcessor.new(that.$options.root, {
                    dateFields: ["date"],
                    dateFormat: "yyyyMMdd",
                    numericFields: ["com", "noncom", "small"],
                });
                processor.processMany(that.$options.preparedData);
               // console.log("that.$options.preparedData=", JSON.parse(JSON.stringify(that.$options.preparedData)));

                 comSeries.data.setAll(that.$options.preparedData);
                 noncomSeries.data.setAll(that.$options.preparedData);
                 smallSeries.data.setAll(that.$options.preparedData);

                /* let cotLegend = cotPanel.plotContainer.children.push(am5stock.StockLegend.new(that.$options.root, {
                    stockChart: stockChart
                  }));
                  cotLegend.data.setAll([comSeries, noncomSeries, smallSeries]);
                 */
            });
        }
    }
};

